import React, { Component } from 'react';
import styled from 'styled-components';
import Information from '../components/information.js';

const PageWrapper = styled.div`
  p {
    color: white;
    margin: 0 auto;
    position: relative;
  }
  a {
    height: 5rem;
    text-decoration: initial;
    &:hover {
      h2 {
        color: #FFC838;
      }
    }
  }
  .background-opacity {
    background: rgba(0,0,0,0.65);
    height: 100%;
    position: absolute;
    width: 100%;
    top 0;
  }
  .fade {
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,0.9) 100%);
    bottom: 0;
    height: 30rem;
    position: absolute;
    width: 100%;
  }
  .top {
    align-items: center;
    background: url(https://webcdn.hirezstudios.com/smite-esports/proleague-com/top-bg.png) no-repeat center;
    display: flex;
    flex-direction: column;
    height: calc(100vw * (1400/2560));
    justify-content: center;
    min-height: 85rem;
    padding: 0 2rem; 
    position: relative;
    @media only screen and (max-width: 600px) {
      min-height: 105rem;
    }
    .logo-container {
      align-items: center;
      border: 4px solid #ffc738;
      display: flex;
      flex-direction: row;
      margin: 2rem;
      justify-content: center;
      padding: 1rem;
      position: relative;
      .info {
        border-right: 4px solid #ffc738;
        padding: 0.5rem 2rem;
      }
      .blog {
        padding: 0.5rem 2rem;
      }
    }
    h1, h2 {
      color: white;
      text-align: center;
      text-transform: uppercase;
    }
    h1 {
      color: #FFC838;
      font-size: 7rem;
      max-width: 45rem;
      z-index: 1;
    }
    h2 {
      font-size: 4.8rem;
      letter-spacing: 3px;
      @media only screen and (max-width: 600px) {
        font-size: 2.8rem;
      }
    }
    .center-wrapper {
      margin: 0 auto;
      max-width: 42rem;
      position: relative;
      width: 100%;
      p {
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding: 1rem;
        text-align: left;
        z-index: 1;
      }
    }
    .learn-more {
      bottom: 11.9rem;
      color: #FFC838;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;
    }
    span {
      bottom: 8.5rem;
      color: #f9c337;
      font-size: 4rem;
      font-weight: 600;
      left: 50%;
      position: absolute;
      transform: rotate(90deg);
      z-index: 1;

    }
  }
  .middle {
    background: url(https://webcdn.hirezstudios.com/smite-esports/proleague-com/bottom-bg.png) no-repeat center;
    height: calc(100vw*(1190/2560));
    min-height: 87.3rem;
    position: relative;
     @media only screen and (max-width: 1500px) {
      min-height: 107.3rem;
    }
    @media only screen and (max-width: 600px) {
      min-height: 97.3rem;
    }
    .information-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      margin: 0 auto;
      max-height: 77.3rem;
      position: relative;     
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      .information {
        p {
          max-width: 40rem;
          &.header {
            display: block;
            text-align: center;
            @media only screen and (max-width: 600px) {
              left: 1.5rem;
              max-width: 32rem;
            }
          }
        }
        .image {
          display: none;
        }
      }
      ul {
        left: -7rem;
        position: relative;
        top: -2rem;
        @media only screen and (max-width: 600px) {
          top: -1rem;
        }
        li {
          color: white;
          padding: 0.6rem;
          &:before {
            content: "•";
            color: #FFC838;
            padding: 0.5rem;
          }
        }
      }
    }
  }
  .bottom {
    align-items: center;
    background: #FFC838;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 24.9rem;
    p {
      color: black;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      width: 100%;
      max-width: 41rem;
    }
    a {
      background: #ffc838;
      border: 2px solid;
      font-family: 'Bebas Neue', cursive;
      font-size: 3rem;
      font-weight: 600;
      letter-spacing: 3px;
      margin: 2rem 0;
      padding: 2rem;
      text-transform: uppercase;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
`;

class CommunityTournament extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: false
    };

  }
  
  render() {
    return (
      <PageWrapper>
        <div id="open-circuit">
          <section className="top">
            <div className="background-opacity" />
            <h1>SMITE Community Tournaments </h1>
            <div className="logo-container">
              <a className="info" href="https://esports.smitegame.com/news/smite-community-tournaments">
                  <h2>Info</h2>
                </a>
              <a className="blog" href="https://esports.smitegame.com/news/how-to-apply-for-smite-community-tournament-support">
                <h2>Blog</h2>
              </a>
            </div>
            <div className="center-wrapper">
              <p>If you’re looking for some competitive action on the Battleground, look no further than SMITE community tournaments. These fan-organized tournaments have long been at the heart of the competitive SMITE ecosystem, and there are plenty of ways you can get involved! </p>
              <p>Whether you’re looking for fun with friends, needing a proving ground for your budding competitive career, or trying to start your own community tournament/league, we have plenty of resources to help you out. </p>
              </div>
            <p className="learn-more">Learn More</p>
            <span>&#8250;</span>
            <div className="fade" />
          </section>
          <section className="middle">
            <div className="background-opacity" />
            <div className="information-container">
              <Information
                header="How to Find Community Tournaments"
                paragraph="If you’re on the hunt for a community tournament to participate in, there are tons of options for you. Our SMITE community organizers host tournaments across all platforms and several regions — including North America, Europe, Latin America, and more."
                paragraph2="To help you keep track of events you might be interested in, we’ve put together some sync-friendly calendars that display all the active community tournaments and community leagues happening across all regions and platforms. Visit the links below to find community tournaments you can join!" />
                <ul>
                  <a href="https://calendar.google.com/calendar?cid=Z2c4N2tvbzk5dHZ2ZmV1OGY5ZWpmcXVxNHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"><li>Community Tournaments</li></a>
                  <a href="https://calendar.google.com/calendar?cid=bnJmYmZycHMwc2NjaW03OGNhMGc5Z3ZzOThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"><li>Community Leagues</li></a>
                </ul>
              <Information
                header="How to Apply for Support"
                paragraph="If you’re already hosting a community tournament/league or you’re hoping to start one, we’re here to help! Hi-Rez Studios is able to provide prizes to community tournaments through in-game rewards and (in some cases) promotional opportunities."
                paragraph2="Visit our community tournament support blog to learn more about the requirements for receiving prizing support from Hi-Rez and how you can get the application process started." />
            </div>
            <div className="fade" />
          </section>
          <section className="bottom">
            <p>Learn more about the requirements for receiving prizing support and how to start the application process.</p>
            <a href="https://esports.smitegame.com/news/how-to-apply-for-smite-community-tournament-support">
              Visit the Blog
            </a>
          </section>
        </div>
      </PageWrapper>
    );
  };
}

export default CommunityTournament;
