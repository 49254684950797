import React, { PureComponent } from 'react';
import styled from 'styled-components';


const InformationWrapper = styled.div`
  .information {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    @media only screen and (max-width: 600px) {
      padding:  2rem 3rem 0px 0px
    }
    .image {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: -2rem;
      max-width: 10rem;
      padding: 2rem 5rem;
      width: 100%;
      @media only screen and (max-width: 600px) {
       display: none;
      }
    }
    p {
      line-height: 2.4rem;
      max-width: 35.6rem;
      padding: 2rem;
      width: 100%;
      @media only screen and (max-width: 600px) {
        max-width: 32rem !important;
      }
      &.paragraph2 {
        padding: 2rem;
        @media only screen and (max-width: 600px) {
          max-width: 32rem !important;
          padding: 0 2rem 0;
        }
      }
      &.header {
        border: 4px solid #ffc838;
        display: initial;
        font-weight: 600;
        left: 2rem;
        padding: 0.5rem 1rem;
        position: relative;
        text-transform: uppercase;
      }
    }
  }
`;

class Information extends PureComponent {
  render(){
    return (
      <InformationWrapper>
        <div className="information">
          <div className="image">
            <img src={`https://webcdn.hirezstudios.com/smite-esports/proleague-com/${this.props.image}.png`} alt={this.props.image} />
          </div>
          <div className="information-text">
            <p className="header">{this.props.header}</p>
            <p>{this.props.paragraph}</p>
            <p className="paragraph2">{this.props.paragraph2}</p>
          </div>
        </div>  
      </InformationWrapper>
    );
  }
}

export default Information;

