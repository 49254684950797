import React, { Component } from 'react';
import styled from 'styled-components';
import Information from '../components/information.js';

const PageWrapper = styled.div`
  p {
    color: white;
    margin: 0 auto;
    position: relative;
  }
  a {
    height: 5rem;
    margin: 3rem;
    text-decoration: initial;
    &:hover {
      h2 {
        color: #FFC838;
        border-bottom: 2px solid #FFC838;
      }
    }
  }
  .background-opacity {
    background: rgba(0,0,0,0.65);
    height: 100%;
    position: absolute;
    width: 100%;
    top 0;
  }
  .fade {
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,0.9) 100%);
    bottom: 0;
    height: 30rem;
    position: absolute;
    width: 100%;
  }
  .top {
    align-items: center;
    background: url(https://webcdn.hirezstudios.com/smite-esports/proleague-com/top-bg.png) no-repeat center;
    display: flex;
    flex-direction: column;
    height: calc(100vw * (1400/2560));
    justify-content: center;
    min-height: 80rem;
    padding: 0 2rem; 
    position: relative;
    @media only screen and (max-width: 600px) {
      min-height: 85rem;
    }
    .logo-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 1rem;
      position: relative;
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
      img {
        height: auto;
        width: 100%; 
      }
      .mobile-links {
        display: none;
      }
      @media only screen and (max-width: 600px) {
        a {
          display: none;
        }
        .mobile-links {
          padding: 1rem 0 5rem;
          display: flex;
          a {
            display: block;
          }
          h2 {
            padding: 1rem 2rem;
            &.info {
              border-right: 2px solid #FFC838;
            }
          }
        }
      }
    }
    h1, h2 {
      color: white;
      text-align: center;
      text-transform: uppercase;
    }
    h1 {
      font-size: 7rem;
    }
    h2 {
      font-size: 4.8rem;
      letter-spacing: 3px;
      padding: 1rem 0;
    }
    .center-wrapper {
      margin: 0 auto;
      max-width: 55rem;
      position: relative;
      width: 100%;
      p {
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding: 1rem;
        text-align: center;
        z-index: 1;
      }
    }
    .learn-more {
      bottom: 11.9rem;
      color: #FFC838;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;
      @media only screen and (max-width: 600px) {
        bottom: 4.9rem;
      }
    }
    span {
      bottom: 8.5rem;
      color: #f9c337;
      font-size: 4rem;
      font-weight: 600;
      left: 50%;
      position: absolute;
      transform: rotate(90deg);
      z-index: 1;
      @media only screen and (max-width: 600px) {
        bottom: 1.5rem;
      }
    }
  }
  .middle {
    background: url(https://webcdn.hirezstudios.com/smite-esports/proleague-com/bottom-bg.png) no-repeat center;
    height: calc(100vw*(1190/2560));
    min-height: 87.3rem;
    position: relative;
    @media only screen and (max-width: 600px) {
      min-height: 97.3rem;
    }
    .information-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      margin: 0 auto;
      max-height: 77.3rem;
      position: relative;     
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      .paragraph2 {
        display: none;
      }
    }
  }
  .bottom {
    align-items: center;
    background: #FFC838;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 24.9rem;
    p {
      color: black;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      width: 100%;
    }
    a {
      background: #ffc838;
      border: 2px solid;
      font-family: 'Bebas Neue', cursive;
      font-size: 3rem;
      font-weight: 600;
      letter-spacing: 3px;
      margin: 2rem 0;
      padding: 2rem;
      text-transform: uppercase;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
`;

class OpenCircuit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: false
    };

  }
  
  render() {
    return (
      <PageWrapper>
        <div id="open-circuit">
          <section className="top">
            <div className="background-opacity" />
            <div className="logo-container">
                <a href="https://esports.smitegame.com/news/smite-open-circuit-details-how-to-participate">
                  <h2>Info</h2>
                </a>
              <div className="logo">
                <img src="https://webcdn.hirezstudios.com/smite-esports/proleague-com/open-circuit-logo.png" alt="Open Circuit" />
              </div>
              <a href="https://esports.smitegame.com/news/smite-open-circuit-season-7-format-details">
                <h2>Blog</h2>
              </a>
              <div className="mobile-links">
                <a href="/">
                  <h2 className="info">Info</h2>
                </a>
                <a href="https://esports.smitegame.com/news/smite-challenger-circuit-season-7-format">
                <h2 className="blog">Blog</h2>
              </a>
              </div>
            </div>
            <div className="center-wrapper">
              <h1>Entry-level Amateur SMITE Esports</h1>
              <p>The SMITE Open Circuit is our entry-level amateur bracket that serves as a starting point for aspiring SMITE esports players.</p>
              </div>
            <p className="learn-more">Learn More</p>
            <span>&#8250;</span>
            <div className="fade" />
          </section>
          <section className="middle">
            <div className="background-opacity" />
            <div className="information-container">
              <Information image="NoRegionLock_Icon" header="No Region Locking" paragraph="This crossplay circuit supports all platforms (PC, Xbox, PS4, Switch) and features 6 teams from North America and 6 teams from Europe -- but there is no region locking for these teams, so international players who can compete on NA or EU servers are allowed to play in the Open Circuit." />
              <Information image="PhasesIcon" header="LAN Events & Relegations" paragraph="The Open Circuit is broken into four phases, with each phase lasting four weeks. The top teams from the end of each phase will qualify for a Circuit Playoff LAN event at the Hi-Rez Esports studio in Atlanta, GA. The top teams from each region will also have the opportunity to face off against Challenger Circuit teams in a relegations bracket at the end of each phase, where they could potentially be promoted into the Challenger Circuit if they’re able to beat the incumbent CC teams." />
              <Information image="BracketsIcon" header="Open-Entry" paragraph="The first open-entry bracket for the SMITE Open Circuit begins March 7.  Visit our blog for details on how to participate." />
            </div>
            <div className="fade" />
          </section>
          <section className="bottom">
            <p>Learn how you can participate in the SMITE Open Circuit.</p>
            <a href="https://esports.smitegame.com/news/smite-open-circuit-season-7-format-details">
              Visit the Blog
            </a>
          </section>
        </div>
      </PageWrapper>
    );
  };
}

export default OpenCircuit;
