// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-challenger-circuit-js": () => import("/Users/doug/Desktop/Hi-Rez/gatsby-playsmiteproleague-com/src/pages/challenger-circuit.js" /* webpackChunkName: "component---src-pages-challenger-circuit-js" */),
  "component---src-pages-community-tournaments-js": () => import("/Users/doug/Desktop/Hi-Rez/gatsby-playsmiteproleague-com/src/pages/community-tournaments.js" /* webpackChunkName: "component---src-pages-community-tournaments-js" */),
  "component---src-pages-index-js": () => import("/Users/doug/Desktop/Hi-Rez/gatsby-playsmiteproleague-com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-circuit-js": () => import("/Users/doug/Desktop/Hi-Rez/gatsby-playsmiteproleague-com/src/pages/open-circuit.js" /* webpackChunkName: "component---src-pages-open-circuit-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/doug/Desktop/Hi-Rez/gatsby-playsmiteproleague-com/.cache/data.json")

